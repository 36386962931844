* {
  margin: 0;
  font-family: 'Poppins';
  font-style: normal;
  --backgoundColor: #252628;
  --greyFont: #ABB2BF;
  --whiteFont: #ffffff;
  --primaryColor: #78E98A;
}

html {
  max-width: 100%;
}

::-webkit-scrollbar {
  display: none;
}

body {
  color: var(--whiteFont);
  text-decoration: none;
  background-color: var(--backgoundColor);
  scroll-behavior: smooth;
}

h1 {
  font-size: 65px;
  font-weight: 700;
  font-style: normal;
  line-height: 98px;
}

p {
  font-size: 1.2rem;
  font-weight: 100;
  line-height: 1.5;
  color: var(--whiteFont);
}

.place {
  position: absolute;
  top: 80vh;
  left: 10px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  font-size: 1.2rem;
}



@media screen and (max-width: 768px) {
  h1 {
    font-size: 40px;
    line-height: 60px;
  }

  p {
    font-size: 1rem;
  }

  .place {
    top: 90vh;
    font-size: 0.5rem;
  }

}

.button {
  position: absolute;
  width: 244px;
  height: 50px;
  left: calc(50% - 244px/2);
  background: #FFFFFF;
  border-radius: 15px;
  top: calc(50% + 50px/2);
  z-index: 3;
  opacity: 0.9;
}

.button:hover {
  cursor: pointer;
  background-color: var(--primaryColor);
  color: var(--backgoundColor);
}

.button__text {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  color: #000000;
}