.projects {
    width: 100%;
    height: 100%;
    text-align: center;
}

.projects__container {
    padding-top: 10vh;
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .projects__container {
        flex-direction: column;
    }
}