header {
    position: fixed;
    padding-left: 10vw;
    padding-right: 10vw;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 80vw;
    z-index: 8;
    height: 10vh;
    border-radius: 0px 0px 15px 15px;
}

.logo-container {
    width: 10%;
    padding-right: 40%;
    align-items: center;
}

.logo {
    margin-left: 2px;
    letter-spacing: 0.05em;
    width: fit-content;
}

.logo:hover {
    cursor: pointer;
}

.navbar {
    width: 50%;
    display: flex;
    justify-content: space-around;
}

a {
    text-decoration: none;
    color: #fff;
    font-weight: lighter;
    font-size: 1rem;
    font-weight: 100;
    z-index: 8;
    width: fit-content;
    height: fit-content;
}

a:hover {
    cursor: pointer;
    color: var(--primaryColor);
}

@media screen and (max-width: 768px) {
    a {
        font-size: 0.5rem;
    }
}