.hero {
    /* background: url(../assets/background.jpg) no-repeat center center; */
    background-size: 100% 100%;
    height: 100vh;
    width: 100%;
    opacity: 0.30;
    z-index: 0;
    mix-blend-mode: normal;
}

.hero-text {
    padding-top: 15vh;
    text-align: center;
    width: 80vw;
    left: 10vw;
    right: 10vw;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    color: #fff;
    text-align: center;
    font-weight: bold;
    z-index: 2;
    position: absolute;
}