.contacts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    height: 80vh;
    margin: 0 auto;
}

.contacts-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    height: 100%;
}

.contacts-text-container h1 {
    font-size: 2.5rem;
    font-weight: 600;
    color: white;
}

.contacts-text-container h2 {
    font-size: 1.2rem;
    font-weight: 400;
    color: rgb(187, 186, 186);
}

.contacts-img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
    height: 100%;
}

.contacts-img {
    width: 100%;
    height: 80%;
}

.contacts-form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.contacts-form input {
    width: 60%;
    height: 50px;
    border: 1px solid #4E8759;
    border-radius: 5px;
    padding: 0 10px;
    background-color: transparent;
    color: white;
}
.contacts-form input:nth-child(3) {
    width: 80%;
    height: 100px;
}


.contacts-form button {
    width: 50%;
    height: 50px;
    border: 1px solid #4E8759;
    border-radius: 5px;
    padding: 0 10px;
    background-color: #4E8759;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
}

.contacts-form button:hover {
    background-color: #3E6A4A;
}


@media screen and (max-width: 768px) {
    .contacts {
        flex-direction: column;
        flex-flow: column-reverse;
        height: 100%;
    }
    .contacts-text-container {
        width: 100%;
        height: 30%;
        text-align: center;
    }
    .contacts-img-container {
        width: 100%;
        height: 70%;
    }
    .contacts-form {
        width: 100%;
        height: 100%;
    }
    .contacts-form input {
        width: 80%;
        margin: 10px auto;
    }
    .contacts-form button {
        width: 80%;
        margin: 10px auto;
    }
}