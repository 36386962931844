.project-card {
    width: 390px;
    /* min-height: 678px; */
    background: rgba(51, 51, 51, 0.4);
    border-radius: 20px;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.project-card__img{
    width: 100%;
    height: 30%;
    border-radius: 20px 20px 0 0;
    background-color: transparent;
    overflow: hidden;
}

.project_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-card__name{
    width: 100%;
    /* height: 5%; */
    padding: 10px 0;
    font-size: 2rem;
    font-weight: 700;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-card__description{
    width: 100%;
    /* height: 35%; */
    padding: 10px 0;
    font-size: 1rem;
    font-weight: lighter;
    color: #fff;
}

.project-card__tools{
    width: 100%;
    /* height: 10%; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}

.label{
    background-color: var(--primaryColor);
    margin: 0 5px;
    padding: 0px 10px;
    border-radius: 10px;
    font-size: 0.9rem;
}

.project-card__button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: var(--primaryColor) 2px solid;
    color: #fff;
    margin: 10px auto;
    border-radius: 10px;
    font-size: 1.1rem;
}

.project-card__button:hover{
    background-color: var(--primaryColor);
    color: #fff;
    cursor: pointer;
    opacity: 0.8;
    color: white;
}

@media screen and (max-width: 868px) {
    .project-card {
        width: 80%;
        /* height: 100%; */
        margin: 10px auto;
        padding: 0 0 10px 0;
    }
    .project-card__img{
        height: 20%;
    }
 
    .project_img{
        width: 100%;
        height: 200px;
    }
}