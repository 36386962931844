.about-me_container{
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-me_logo_container{
    margin-left: 10%;
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-me_text_container{
    width: 50%;
    margin-right: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.about-me_icons{
    width: 70%;
    height: 10%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #4E8759;
    border-radius: 50px;
    margin: 10px auto;
    color: white;
}

.icon:hover{
    color: var(--primaryColor);
}

@media screen and (max-width: 768px) {
    .about-me_container{
        height: 100vh;
        flex-direction: column;
    }
    .about-me_logo_container{
        width: 80%;
        margin: 0 auto;
        height: 30%;
    }
    .about-me_text_container{
        width: 90%;
        height: 50%;
        margin: 0 auto;
        text-align: center;
    }
    .about-me_icons{
        width: 100%;
        height: 10%;
    }
    img{
        width: 80%;
        height: 100%;
    }
}